
.table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.th, .td {
  border: 1px solid #d6d1d1;
  text-align: left;
  padding: 0px;
}

.tr:nth-child(even) {
  background-color: #dddddd;
}



.fixedTable th, .fixedTable td {
  width: 200px; /* Adjust column width as needed */
  overflow: hidden;
  white-space: nowrap; /* Prevent text wrapping */
  text-overflow: ellipsis; /* Add '...' if content overflows */
  /* border: 1px solid #ccc; */
  padding: 3px;

 
}

